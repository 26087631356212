.servico-img-container {
  width: 25%;
  height: 400px;
  min-width: 250px;
}
.servico-img-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: relative;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-pimary-900);
}
.servico-img-container1:hover {
  opacity: 1;
}
.servico-img-image {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 100;
  position: absolute;
  object-fit: cover;
}
.servico-img-container2 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.servico-img-text {
  color: var(--dl-color-secondary-300);
  width: 100%;
  font-size: 1.25rem;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.servico-img-text1 {
  color: var(--dl-color-secondary-300);
  display: none;
  align-self: center;
  text-transform: none;
}
.servico-img-root-class-name {
  width: 25%;
}
.servico-img-root-class-name1 {
  width: 25%;
}
.servico-img-root-class-name2 {
  width: 25%;
}
.servico-img-root-class-name3 {
  width: 25%;
}
.servico-img-root-class-name5 {
  width: 25%;
}
.servico-img-root-class-name6 {
  width: 25%;
}
.servico-img-root-class-name7 {
  width: 25%;
}
@media(max-width: 479px) {
  .servico-img-root-class-name {
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name1 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name2 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name3 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name5 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name6 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
  .servico-img-root-class-name7 {
    width: 25%;
    height: 400px;
    min-width: 250px;
  }
}
