.team-element-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-secondary-300);
}
.team-element-image {
  width: 300px;
  height: 450px;
  align-self: center;
  object-fit: cover;
}
.team-element-container1 {
  flex: 1;
  align-items: center;
}
.team-element-text {
  color: var(--dl-color-pimary-900);
  text-align: center;
}



@media(max-width: 479px) {
  .team-element-container {
    justify-content: center;
  }
}
