.page-actividade-container {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;
}
.page-actividade-container1 {
  align-items: center;
}

