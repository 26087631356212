.form-textarea-container {
  width: 100%;
  position: relative;
}
.form-textarea-text {
  color: var(--dl-color-pimary-800);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.form-textarea-textarea {
  color: var(--dl-color-secondary-100);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-pimary-900);
}
.form-textarea-textarea:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px #4AA4E3;
}

