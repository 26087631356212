.contact-form-form {
  width: 100%;
}
.contact-form-text {
  color: var(--dl-color-pimary-900);
  width: auto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
}
.contact-form-text1 {
  color: var(--dl-color-pimary-700);
}
.contact-form-textinput {
  color: var(--dl-color-secondary-100);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-pimary-900);
}
.contact-form-textinput:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}
.contact-form-textinput1 {
  color: var(--dl-color-secondary-100);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-pimary-900);
}
.contact-form-textinput1:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}
.contact-form-textinput2 {
  color: var(--dl-color-secondary-100);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-pimary-900);
}
.contact-form-textinput2:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}
.contact-form-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-form-button {
  color: var(--dl-color-gray-white);
  align-self: center;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);
  margin-top: var(--dl-space-space-doubleunit);
  transition: 0.3s;
  line-height: 1;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  background-color: var(--dl-color-pimary-500);
}
.contact-form-button:hover {
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  background-color: var(--dl-color-pimary-300);
}
.contact-form-button:active {
  background-color: var(--dl-color-secondary-100);
}
.contact-form-root-class-name {
  width: 100%;
  align-self: center;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.contact-form-root-class-name1 {
  align-self: center;
}
@media(max-width: 479px) {
  .contact-form-text {
    text-align: left;
  }
}
