.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-secondary-300);
}
.footer-container1 {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.footer-image {
  display: none;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-navlink {
  color: var(--dl-color-pimary-900);
  display: none;
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.footer-navlink1 {
  color: var(--dl-color-pimary-900);
  display: none;
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.footer-navlink2 {
  color: var(--dl-color-pimary-900);
  display: none;
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.footer-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-900);
}
.footer-text {
  color: var(--dl-color-pimary-800);
  text-align: center;
}

