.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-headersize);
  display: flex;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 200;
  position: fixed;
  box-shadow: 0px 5px 10px 0px rgba(97, 107, 125, 0.5);
  flex-direction: row;
  background-color: var(--dl-color-secondary-300);
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-heading {
  color: var(--dl-color-gray-white);
  font-size: 1.125rem;
  font-style: normal;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-container1 {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  justify-content: flex-end;
}
.header-navlink1 {
  color: var(--dl-color-gray-white);
  display: none;
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-text {
  color: var(--dl-color-gray-white);
  display: none;
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-navlink2 {
  color: var(--dl-color-gray-white);
  display: none;
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.header-root-class-name {
  margin-bottom: 0px;
}
.header-root-class-name1 {
  margin-bottom: 0px;
}
