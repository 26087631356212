.correia-de-castro-advogados-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.correia-de-castro-advogados-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIxfHxsYXd8ZW58MHx8fHwxNjU2OTc5MTgy&ixlib=rb-1.2.1&w=1500');
  background-position: center;
}
.correia-de-castro-advogados-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.correia-de-castro-advogados-link {
  color: var(--dl-color-gray-white);
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.correia-de-castro-advogados-text {
  color: var(--dl-color-gray-white);
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.correia-de-castro-advogados-link1 {
  color: var(--dl-color-gray-white);
  font-size: 0.87rem;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.correia-de-castro-advogados-container1 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.correia-de-castro-advogados-container2 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.correia-de-castro-advogados-text01 {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.correia-de-castro-advogados-text02 {
  color: var(--dl-color-gray-900);
  max-width: 600px;
  text-align: center;
  text-transform: uppercase;
}
.correia-de-castro-advogados-image {
  right: 0px;
  width: 100%;
  bottom: -2px;
  position: absolute;
  object-fit: fill;
}
.correia-de-castro-advogados-link2 {
  color: var(--dl-color-pimary-900);
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.correia-de-castro-advogados-link3 {
  color: var(--dl-color-pimary-900);
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.correia-de-castro-advogados-link4 {
  color: var(--dl-color-pimary-900);
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.35rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.35rem;
  text-transform: none;
  text-decoration: none;
}
.correia-de-castro-advogados-container7 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.correia-de-castro-advogados-text05 {
  color: #000000;
  text-align: center;
}
.correia-de-castro-advogados-text08 {
  color: #000000;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
}
.correia-de-castro-advogados-container8 {
  width: 100%;
  bottom: 0;
  height: auto;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.correia-de-castro-advogados-text11 {
  color: var(--dl-color-pimary-800);
  text-align: center;
}
.correia-de-castro-advogados-text14 {
  color: var(--dl-color-pimary-800);
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
@media(max-width: 479px) {
  .correia-de-castro-advogados-container2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .correia-de-castro-advogados-container7 {
    height: auto;
  }
  .correia-de-castro-advogados-container8 {
    height: auto;
  }
}
