.service-card-right-container {
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.service-card-right-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-secondary-300);
}
.service-card-right-container2 {
  flex: 1;
  align-items: flex-start;
  background-color: transparent;
}
.service-card-right-text {
  color: var(--dl-color-pimary-900);
  text-align: left;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
.service-card-right-text1 {
  color: var(--dl-color-pimary-900);
  width: 100%;
  font-size: 1.125rem;
  text-align: left;
  font-weight: 300;
  line-height: 1.625;
}
.service-card-right-image {
  width: 300px;
  height: 300px;
  align-self: center;
  object-fit: cover;
}



@media(max-width: 479px) {
  .service-card-right-container1 {
    justify-content: center;
  }
}
