.hero-hero {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-herosize);
  display: flex;
  position: relative;
  flex-direction: column;
  background-size: cover;
}
.hero-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 75px;
  justify-content: center;
}
.hero-text {
  top: 0px;
  left: 276px;
  color: var(--dl-color-gray-white);
  right: auto;
  bottom: 0px;
  margin: auto;
  z-index: 100;
  text-align: center;
}
.hero-bg {
  top: auto;
  flex: 0 0 auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  display: flex;
  opacity: 0.4;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-black);
}
.hero-root-class-name {
  background-image: 584d2166-a6f0-4e67-a4f2-0ae5cf254bbb;
}
.hero-root-class-name1 {
  background-image: 8396dabb-6046-430f-b90a-6a1a3e6966ba;
}
.hero-root-class-name2 {
  background-image: 16a6e132-00c4-44a3-ab5c-10d5c5011656;
}

.hero-root-class-name4 {
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1586281380426-f644f2dc6ada?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE1fHxwcml2YWN5JTIwcG9saWN5fGVufDB8fHx8MTY2Mjc2NjU2NQ&ixlib=rb-1.2.1&w=1500');
}
