.map-map {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
}
.map-iframe {
  width: 100%;
  height: 100%;
}
.map-root-class-name {
  margin-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-pimary-900);
}
